
export const symbolEnums = Object.freeze({
    currency: [
        {
            "symbol": "₠",
            "name": "Euro-Currency Sign",
            "entityCode": "",
            "decimalCode": "&#8352;",
            "hexCode": "&#x20a0;"
        },
        {
            "symbol": "₡",
            "name": "Colon Sign",
            "entityCode": "",
            "decimalCode": "&#8353;",
            "hexCode": "&#x20a1;"
        },
        {
            "symbol": "₢",
            "name": "Cruzeiro Sign",
            "entityCode": "",
            "decimalCode": "&#8354;",
            "hexCode": "&#x20a2;"
        },
        {
            "symbol": "₣",
            "name": "French Franc Sign",
            "entityCode": "",
            "decimalCode": "&#8355;",
            "hexCode": "&#x20a3;"
        },
        {
            "symbol": "₤",
            "name": "Lira Sign",
            "entityCode": "",
            "decimalCode": "&#8356;",
            "hexCode": "&#x20a4;"
        },
        {
            "symbol": "₥",
            "name": "Mill Sign",
            "entityCode": "",
            "decimalCode": "&#8357;",
            "hexCode": "&#x20a5;"
        },
        {
            "symbol": "₠",
            "name": "Euro-Currency Sign",
            "entityCode": "",
            "decimalCode": "&#8352;",
            "hexCode": "&#x20a0;"
        },
        {
            "symbol": "₡",
            "name": "Colon Sign",
            "entityCode": "",
            "decimalCode": "&#8353;",
            "hexCode": "&#x20a1;"
        },
        {
            "symbol": "₢",
            "name": "Cruzeiro Sign",
            "entityCode": "",
            "decimalCode": "&#8354;",
            "hexCode": "&#x20a2;"
        },
        {
            "symbol": "₣",
            "name": "French Franc Sign",
            "entityCode": "",
            "decimalCode": "&#8355;",
            "hexCode": "&#x20a3;"
        },
        {
            "symbol": "₤",
            "name": "Lira Sign",
            "entityCode": "",
            "decimalCode": "&#8356;",
            "hexCode": "&#x20a4;"
        },
        {
            "symbol": "₥",
            "name": "Mill Sign",
            "entityCode": "",
            "decimalCode": "&#8357;",
            "hexCode": "&#x20a5;"
        },
        {
            "symbol": "₠",
            "name": "Euro-Currency Sign",
            "entityCode": "",
            "decimalCode": "&#8352;",
            "hexCode": "&#x20a0;"
        },
        {
            "symbol": "₡",
            "name": "Colon Sign",
            "entityCode": "",
            "decimalCode": "&#8353;",
            "hexCode": "&#x20a1;"
        },
        {
            "symbol": "₢",
            "name": "Cruzeiro Sign",
            "entityCode": "",
            "decimalCode": "&#8354;",
            "hexCode": "&#x20a2;"
        },
        {
            "symbol": "₣",
            "name": "French Franc Sign",
            "entityCode": "",
            "decimalCode": "&#8355;",
            "hexCode": "&#x20a3;"
        },
        {
            "symbol": "₤",
            "name": "Lira Sign",
            "entityCode": "",
            "decimalCode": "&#8356;",
            "hexCode": "&#x20a4;"
        },
        {
            "symbol": "₥",
            "name": "Mill Sign",
            "entityCode": "",
            "decimalCode": "&#8357;",
            "hexCode": "&#x20a5;"
        },
        {
            "symbol": "₦",
            "name": "Naira Sign",
            "entityCode": "",
            "decimalCode": "&#8358;",
            "hexCode": "&#x20a6;"
        },
        {
            "symbol": "₧",
            "name": "Peseta Sign",
            "entityCode": "",
            "decimalCode": "&#8359;",
            "hexCode": "&#x20a7;"
        },
        {
            "symbol": "₨",
            "name": "Rupee Sign",
            "entityCode": "",
            "decimalCode": "&#8360;",
            "hexCode": "&#x20a8;"
        },
        {
            "symbol": "₩",
            "name": "Won Sign",
            "entityCode": "",
            "decimalCode": "&#8361;",
            "hexCode": "&#x20a9;"
        },
        {
            "symbol": "₪",
            "name": "New Sheqel Sign",
            "entityCode": "",
            "decimalCode": "&#8362;",
            "hexCode": "&#x20aa;"
        },
        {
            "symbol": "₫",
            "name": "Dong Sign",
            "entityCode": "",
            "decimalCode": "&#8363;",
            "hexCode": "&#x20ab;"
        },
        {
            "symbol": "€",
            "name": "Euro Sign",
            "entityCode": "&euro;",
            "decimalCode": "&#8364;",
            "hexCode": "&#x20ac;"
        },
        {
            "symbol": "₭",
            "name": "Kip Sign",
            "entityCode": "",
            "decimalCode": "&#8365;",
            "hexCode": "&#x20ad;"
        },
        {
            "symbol": "₮",
            "name": "Tugrik Sign",
            "entityCode": "",
            "decimalCode": "&#8366;",
            "hexCode": "&#x20ae;"
        },
        {
            "symbol": "₯",
            "name": "Drachma Sign",
            "entityCode": "",
            "decimalCode": "&#8367;",
            "hexCode": "&#x20af;"
        },
        {
            "symbol": "₰",
            "name": "German Penny Sign",
            "entityCode": "",
            "decimalCode": "&#8368;",
            "hexCode": "&#x20b0;"
        },
        {
            "symbol": "₱",
            "name": "Peso Sign",
            "entityCode": "",
            "decimalCode": "&#8369;",
            "hexCode": "&#x20b1;"
        },
        {
            "symbol": "₲",
            "name": "Guarani Sign",
            "entityCode": "",
            "decimalCode": "&#8370;",
            "hexCode": "&#x20b2;"
        },
        {
            "symbol": "₳",
            "name": "Austral Sign",
            "entityCode": "",
            "decimalCode": "&#8371;",
            "hexCode": "&#x20b3;"
        },
        {
            "symbol": "₴",
            "name": "Hryvnia Sign",
            "entityCode": "",
            "decimalCode": "&#8372;",
            "hexCode": "&#x20b4;"
        },
        {
            "symbol": "₵",
            "name": "Cedi Sign",
            "entityCode": "",
            "decimalCode": "&#8373;",
            "hexCode": "&#x20b5;"
        },
        {
            "symbol": "₶",
            "name": "Livre Tournois Sign",
            "entityCode": "",
            "decimalCode": "&#8374;",
            "hexCode": "&#x20b6;"
        },
        {
            "symbol": "₷",
            "name": "Spesmilo Sign",
            "entityCode": "",
            "decimalCode": "&#8375;",
            "hexCode": "&#x20b7;"
        },
        {
            "symbol": "₸",
            "name": "Tenge Sign",
            "entityCode": "",
            "decimalCode": "&#8376;",
            "hexCode": "&#x20b8;"
        },
        {
            "symbol": "₹",
            "name": "Indian Rupee Sign",
            "entityCode": "",
            "decimalCode": "&#8377;",
            "hexCode": "&#x20b9;"
        }
    ],
    greek: [
        {
            "symbol": "Ͱ",
            "name": "Greek Capital Letter Heta",
            "entityCode": "",
            "decimalCode": "&#880;",
            "hexCode": "&#x370;"
        },
        {
            "symbol": "ͱ",
            "name": "Greek Small Letter Heta",
            "entityCode": "",
            "decimalCode": "&#881;",
            "hexCode": "&#x371;"
        },
        {
            "symbol": "Ͳ",
            "name": "Greek Capital Letter Archaic Sampi",
            "entityCode": "",
            "decimalCode": "&#882;",
            "hexCode": "&#x372;"
        },
        {
            "symbol": "ͳ",
            "name": "Greek Small Letter Archaic Sampi",
            "entityCode": "",
            "decimalCode": "&#883;",
            "hexCode": "&#x373;"
        },
        {
            "symbol": "ʹ",
            "name": "Greek Numeral Sign",
            "entityCode": "",
            "decimalCode": "&#884;",
            "hexCode": "&#x374;"
        },
        {
            "symbol": "͵",
            "name": "Greek Lower Numeral Sign",
            "entityCode": "",
            "decimalCode": "&#885;",
            "hexCode": "&#x375;"
        },
        {
            "symbol": "Ͷ",
            "name": "Greek Capital Letter Pamphylian Digamma",
            "entityCode": "",
            "decimalCode": "&#886;",
            "hexCode": "&#x376;"
        },
        {
            "symbol": "ͷ",
            "name": "Greek Small Letter Pamphylian Digamma",
            "entityCode": "",
            "decimalCode": "&#887;",
            "hexCode": "&#x377;"
        },
        {
            "symbol": "ͺ",
            "name": "Greek Ypogegrammeni",
            "entityCode": "",
            "decimalCode": "&#890;",
            "hexCode": "&#x37a;"
        },
        {
            "symbol": "ͻ",
            "name": "Greek Small Reversed Lunate Sigma Symbol",
            "entityCode": "",
            "decimalCode": "&#891;",
            "hexCode": "&#x37b;"
        },
        {
            "symbol": "ͼ",
            "name": "Greek Small Dotted Lunate Sigma Symbol",
            "entityCode": "",
            "decimalCode": "&#892;",
            "hexCode": "&#x37c;"
        },
        {
            "symbol": "ͽ",
            "name": "Greek Small Reversed Dotted Lunate Sigma Symbol",
            "entityCode": "",
            "decimalCode": "&#893;",
            "hexCode": "&#x37d;"
        },
        {
            "symbol": ";",
            "name": "Greek Question Mark",
            "entityCode": "",
            "decimalCode": "&#894;",
            "hexCode": "&#x37e;"
        },
        {
            "symbol": "΄",
            "name": "Greek Tonos",
            "entityCode": "",
            "decimalCode": "&#900;",
            "hexCode": "&#x384;"
        },
        {
            "symbol": "΅",
            "name": "Greek Dialytika Tonos",
            "entityCode": "",
            "decimalCode": "&#901;",
            "hexCode": "&#x385;"
        },
        {
            "symbol": "Ά",
            "name": "Greek Capital Letter Alpha With Tonos",
            "entityCode": "",
            "decimalCode": "&#902;",
            "hexCode": "&#x386;"
        },
        {
            "symbol": "·",
            "name": "Greek Ano Teleia",
            "entityCode": "",
            "decimalCode": "&#903;",
            "hexCode": "&#x387;"
        },
        {
            "symbol": "Έ",
            "name": "Greek Capital Letter Epsilon With Tonos",
            "entityCode": "",
            "decimalCode": "&#904;",
            "hexCode": "&#x388;"
        },
        {
            "symbol": "Ή",
            "name": "Greek Capital Letter Eta With Tonos",
            "entityCode": "",
            "decimalCode": "&#905;",
            "hexCode": "&#x389;"
        },
        {
            "symbol": "Ί",
            "name": "Greek Capital Letter Iota With Tonos",
            "entityCode": "",
            "decimalCode": "&#906;",
            "hexCode": "&#x38a;"
        },
        {
            "symbol": "Ό",
            "name": "Greek Capital Letter Omicron With Tonos",
            "entityCode": "",
            "decimalCode": "&#908;",
            "hexCode": "&#x38c;"
        },
        {
            "symbol": "Ύ",
            "name": "Greek Capital Letter Upsilon With Tonos",
            "entityCode": "",
            "decimalCode": "&#910;",
            "hexCode": "&#x38e;"
        },
        {
            "symbol": "Ώ",
            "name": "Greek Capital Letter Omega With Tonos",
            "entityCode": "",
            "decimalCode": "&#911;",
            "hexCode": "&#x38f;"
        },
        {
            "symbol": "ΐ",
            "name": "Greek Small Letter Iota With Dialytika And Tonos",
            "entityCode": "",
            "decimalCode": "&#912;",
            "hexCode": "&#x390;"
        },
        {
            "symbol": "Α",
            "name": "Greek Capital Letter Alpha",
            "entityCode": "&Alpha;",
            "decimalCode": "&#913;",
            "hexCode": "&#x391;"
        },
        {
            "symbol": "Β",
            "name": "Greek Capital Letter Beta",
            "entityCode": "&Beta;",
            "decimalCode": "&#914;",
            "hexCode": "&#x392;"
        },
        {
            "symbol": "Γ",
            "name": "Greek Capital Letter Gamma",
            "entityCode": "&Gamma;",
            "decimalCode": "&#915;",
            "hexCode": "&#x393;"
        },
        {
            "symbol": "Δ",
            "name": "Greek Capital Letter Delta",
            "entityCode": "&Delta;",
            "decimalCode": "&#916;",
            "hexCode": "&#x394;"
        },
        {
            "symbol": "Ε",
            "name": "Greek Capital Letter Epsilon",
            "entityCode": "&Epsilon;",
            "decimalCode": "&#917;",
            "hexCode": "&#x395;"
        },
        {
            "symbol": "Ζ",
            "name": "Greek Capital Letter Zeta",
            "entityCode": "&Zeta;",
            "decimalCode": "&#918;",
            "hexCode": "&#x396;"
        },
        {
            "symbol": "Η",
            "name": "Greek Capital Letter Eta",
            "entityCode": "&Eta;",
            "decimalCode": "&#919;",
            "hexCode": "&#x397;"
        },
        {
            "symbol": "Θ",
            "name": "Greek Capital Letter Theta",
            "entityCode": "&Theta;",
            "decimalCode": "&#920;",
            "hexCode": "&#x398;"
        },
        {
            "symbol": "Ι",
            "name": "Greek Capital Letter Iota",
            "entityCode": "&Iota;",
            "decimalCode": "&#921;",
            "hexCode": "&#x399;"
        },
        {
            "symbol": "Κ",
            "name": "Greek Capital Letter Kappa",
            "entityCode": "&Kappa;",
            "decimalCode": "&#922;",
            "hexCode": "&#x39a;"
        },
        {
            "symbol": "Λ",
            "name": "Greek Capital Letter Lamda",
            "entityCode": "&Lambda;",
            "decimalCode": "&#923;",
            "hexCode": "&#x39b;"
        },
        {
            "symbol": "Μ",
            "name": "Greek Capital Letter Mu",
            "entityCode": "&Mu;",
            "decimalCode": "&#924;",
            "hexCode": "&#x39c;"
        },
        {
            "symbol": "Ν",
            "name": "Greek Capital Letter Nu",
            "entityCode": "&Nu;",
            "decimalCode": "&#925;",
            "hexCode": "&#x39d;"
        },
        {
            "symbol": "Ξ",
            "name": "Greek Capital Letter Xi",
            "entityCode": "&Xi;",
            "decimalCode": "&#926;",
            "hexCode": "&#x39e;"
        },
        {
            "symbol": "Ο",
            "name": "Greek Capital Letter Omicron",
            "entityCode": "&Omicron;",
            "decimalCode": "&#927;",
            "hexCode": "&#x39f;"
        },
        {
            "symbol": "Π",
            "name": "Greek Capital Letter Pi",
            "entityCode": "&Pi;",
            "decimalCode": "&#928;",
            "hexCode": "&#x3a0;"
        },
        {
            "symbol": "Ρ",
            "name": "Greek Capital Letter Rho",
            "entityCode": "&Rho;",
            "decimalCode": "&#929;",
            "hexCode": "&#x3a1;"
        },
        {
            "symbol": "Σ",
            "name": "Greek Capital Letter Sigma",
            "entityCode": "&Sigma;",
            "decimalCode": "&#931;",
            "hexCode": "&#x3a3;"
        },
        {
            "symbol": "Τ",
            "name": "Greek Capital Letter Tau",
            "entityCode": "&Tau;",
            "decimalCode": "&#932;",
            "hexCode": "&#x3a4;"
        },
        {
            "symbol": "Υ",
            "name": "Greek Capital Letter Upsilon",
            "entityCode": "&Upsilon;",
            "decimalCode": "&#933;",
            "hexCode": "&#x3a5;"
        },
        {
            "symbol": "Φ",
            "name": "Greek Capital Letter Phi",
            "entityCode": "&Phi;",
            "decimalCode": "&#934;",
            "hexCode": "&#x3a6;"
        },
        {
            "symbol": "Χ",
            "name": "Greek Capital Letter Chi",
            "entityCode": "&Chi;",
            "decimalCode": "&#935;",
            "hexCode": "&#x3a7;"
        },
        {
            "symbol": "Ψ",
            "name": "Greek Capital Letter Psi",
            "entityCode": "&Psi;",
            "decimalCode": "&#936;",
            "hexCode": "&#x3a8;"
        },
        {
            "symbol": "Ω",
            "name": "Greek Capital Letter Omega",
            "entityCode": "&Omega;",
            "decimalCode": "&#937;",
            "hexCode": "&#x3a9;"
        },
        {
            "symbol": "Ϊ",
            "name": "Greek Capital Letter Iota With Dialytika",
            "entityCode": "",
            "decimalCode": "&#938;",
            "hexCode": "&#x3aa;"
        },
        {
            "symbol": "Ϋ",
            "name": "Greek Capital Letter Upsilon With Dialytika",
            "entityCode": "",
            "decimalCode": "&#939;",
            "hexCode": "&#x3ab;"
        },
        {
            "symbol": "ά",
            "name": "Greek Small Letter Alpha With Tonos",
            "entityCode": "",
            "decimalCode": "&#940;",
            "hexCode": "&#x3ac;"
        },
        {
            "symbol": "έ",
            "name": "Greek Small Letter Epsilon With Tonos",
            "entityCode": "",
            "decimalCode": "&#941;",
            "hexCode": "&#x3ad;"
        },
        {
            "symbol": "ή",
            "name": "Greek Small Letter Eta With Tonos",
            "entityCode": "",
            "decimalCode": "&#942;",
            "hexCode": "&#x3ae;"
        },
        {
            "symbol": "ί",
            "name": "Greek Small Letter Iota With Tonos",
            "entityCode": "",
            "decimalCode": "&#943;",
            "hexCode": "&#x3af;"
        },
        {
            "symbol": "ΰ",
            "name": "Greek Small Letter Upsilon With Dialytika And Tonos",
            "entityCode": "",
            "decimalCode": "&#944;",
            "hexCode": "&#x3b0;"
        },
        {
            "symbol": "α",
            "name": "Greek Small Letter Alpha",
            "entityCode": "&alpha;",
            "decimalCode": "&#945;",
            "hexCode": "&#x3b1;"
        },
        {
            "symbol": "β",
            "name": "Greek Small Letter Beta",
            "entityCode": "&beta;",
            "decimalCode": "&#946;",
            "hexCode": "&#x3b2;"
        },
        {
            "symbol": "γ",
            "name": "Greek Small Letter Gamma",
            "entityCode": "&gamma;",
            "decimalCode": "&#947;",
            "hexCode": "&#x3b3;"
        },
        {
            "symbol": "δ",
            "name": "Greek Small Letter Delta",
            "entityCode": "&delta;",
            "decimalCode": "&#948;",
            "hexCode": "&#x3b4;"
        },
        {
            "symbol": "ε",
            "name": "Greek Small Letter Epsilon",
            "entityCode": "&epsilon;",
            "decimalCode": "&#949;",
            "hexCode": "&#x3b5;"
        },
        {
            "symbol": "ζ",
            "name": "Greek Small Letter Zeta",
            "entityCode": "&zeta;",
            "decimalCode": "&#950;",
            "hexCode": "&#x3b6;"
        },
        {
            "symbol": "η",
            "name": "Greek Small Letter Eta",
            "entityCode": "&eta;",
            "decimalCode": "&#951;",
            "hexCode": "&#x3b7;"
        },
        {
            "symbol": "θ",
            "name": "Greek Small Letter Theta",
            "entityCode": "&theta;",
            "decimalCode": "&#952;",
            "hexCode": "&#x3b8;"
        },
        {
            "symbol": "ι",
            "name": "Greek Small Letter Iota",
            "entityCode": "&iota;",
            "decimalCode": "&#953;",
            "hexCode": "&#x3b9;"
        },
        {
            "symbol": "κ",
            "name": "Greek Small Letter Kappa",
            "entityCode": "&kappa;",
            "decimalCode": "&#954;",
            "hexCode": "&#x3ba;"
        },
        {
            "symbol": "λ",
            "name": "Greek Small Letter Lamda",
            "entityCode": "&lambda;",
            "decimalCode": "&#955;",
            "hexCode": "&#x3bb;"
        },
        {
            "symbol": "μ",
            "name": "Greek Small Letter Mu",
            "entityCode": "&mu;",
            "decimalCode": "&#956;",
            "hexCode": "&#x3bc;"
        },
        {
            "symbol": "ν",
            "name": "Greek Small Letter Nu",
            "entityCode": "&nu;",
            "decimalCode": "&#957;",
            "hexCode": "&#x3bd;"
        },
        {
            "symbol": "ξ",
            "name": "Greek Small Letter Xi",
            "entityCode": "&xi;",
            "decimalCode": "&#958;",
            "hexCode": "&#x3be;"
        },
        {
            "symbol": "ο",
            "name": "Greek Small Letter Omicron",
            "entityCode": "&omicron;",
            "decimalCode": "&#959;",
            "hexCode": "&#x3bf;"
        },
        {
            "symbol": "π",
            "name": "Greek Small Letter Pi",
            "entityCode": "&pi;",
            "decimalCode": "&#960;",
            "hexCode": "&#x3c0;"
        },
        {
            "symbol": "ρ",
            "name": "Greek Small Letter Rho",
            "entityCode": "&rho;",
            "decimalCode": "&#961;",
            "hexCode": "&#x3c1;"
        },
        {
            "symbol": "ς",
            "name": "Greek Small Letter Final Sigma",
            "entityCode": "&sigmaf;",
            "decimalCode": "&#962;",
            "hexCode": "&#x3c2;"
        },
        {
            "symbol": "σ",
            "name": "Greek Small Letter Sigma",
            "entityCode": "&sigma;",
            "decimalCode": "&#963;",
            "hexCode": "&#x3c3;"
        },
        {
            "symbol": "τ",
            "name": "Greek Small Letter Tau",
            "entityCode": "&tau;",
            "decimalCode": "&#964;",
            "hexCode": "&#x3c4;"
        },
        {
            "symbol": "υ",
            "name": "Greek Small Letter Upsilon",
            "entityCode": "&upsilon;",
            "decimalCode": "&#965;",
            "hexCode": "&#x3c5;"
        },
        {
            "symbol": "φ",
            "name": "Greek Small Letter Phi",
            "entityCode": "&phi;",
            "decimalCode": "&#966;",
            "hexCode": "&#x3c6;"
        },
        {
            "symbol": "χ",
            "name": "Greek Small Letter Chi",
            "entityCode": "&chi;",
            "decimalCode": "&#967;",
            "hexCode": "&#x3c7;"
        },
        {
            "symbol": "ψ",
            "name": "Greek Small Letter Psi",
            "entityCode": "&psi;",
            "decimalCode": "&#968;",
            "hexCode": "&#x3c8;"
        },
        {
            "symbol": "ω",
            "name": "Greek Small Letter Omega",
            "entityCode": "&omega;",
            "decimalCode": "&#969;",
            "hexCode": "&#x3c9;"
        },
        {
            "symbol": "ϊ",
            "name": "Greek Small Letter Iota With Dialytika",
            "entityCode": "",
            "decimalCode": "&#970;",
            "hexCode": "&#x3ca;"
        },
        {
            "symbol": "ϋ",
            "name": "Greek Small Letter Upsilon With Dialytika",
            "entityCode": "",
            "decimalCode": "&#971;",
            "hexCode": "&#x3cb;"
        },
        {
            "symbol": "ό",
            "name": "Greek Small Letter Omicron With Tonos",
            "entityCode": "",
            "decimalCode": "&#972;",
            "hexCode": "&#x3cc;"
        },
        {
            "symbol": "ύ",
            "name": "Greek Small Letter Upsilon With Tonos",
            "entityCode": "",
            "decimalCode": "&#973;",
            "hexCode": "&#x3cd;"
        },
        {
            "symbol": "ώ",
            "name": "Greek Small Letter Omega With Tonos",
            "entityCode": "",
            "decimalCode": "&#974;",
            "hexCode": "&#x3ce;"
        },
        {
            "symbol": "Ϗ",
            "name": "Greek Capital Kai Symbol",
            "entityCode": "",
            "decimalCode": "&#975;",
            "hexCode": "&#x3cf;"
        },
        {
            "symbol": "ϐ",
            "name": "Greek Beta Symbol",
            "entityCode": "",
            "decimalCode": "&#976;",
            "hexCode": "&#x3d0;"
        },
        {
            "symbol": "ϑ",
            "name": "Greek Theta Symbol",
            "entityCode": "&thetasym;",
            "decimalCode": "&#977;",
            "hexCode": "&#x3d1;"
        },
        {
            "symbol": "ϒ",
            "name": "Greek Upsilon With Hook Symbol",
            "entityCode": "&upsih;",
            "decimalCode": "&#978;",
            "hexCode": "&#x3d2;"
        },
        {
            "symbol": "ϓ",
            "name": "Greek Upsilon With Acute And Hook Symbol",
            "entityCode": "",
            "decimalCode": "&#979;",
            "hexCode": "&#x3d3;"
        },
        {
            "symbol": "ϔ",
            "name": "Greek Upsilon With Diaeresis And Hook Symbol",
            "entityCode": "",
            "decimalCode": "&#980;",
            "hexCode": "&#x3d4;"
        },
        {
            "symbol": "ϕ",
            "name": "Greek Phi Symbol",
            "entityCode": "",
            "decimalCode": "&#981;",
            "hexCode": "&#x3d5;"
        },
        {
            "symbol": "ϖ",
            "name": "Greek Pi Symbol",
            "entityCode": "&piv;",
            "decimalCode": "&#982;",
            "hexCode": "&#x3d6;"
        },
        {
            "symbol": "ϗ",
            "name": "Greek Kai Symbol",
            "entityCode": "",
            "decimalCode": "&#983;",
            "hexCode": "&#x3d7;"
        },
        {
            "symbol": "Ϙ",
            "name": "Greek Letter Archaic Koppa",
            "entityCode": "",
            "decimalCode": "&#984;",
            "hexCode": "&#x3d8;"
        },
        {
            "symbol": "ϙ",
            "name": "Greek Small Letter Archaic Koppa",
            "entityCode": "",
            "decimalCode": "&#985;",
            "hexCode": "&#x3d9;"
        },
        {
            "symbol": "Ϛ",
            "name": "Greek Letter Stigma",
            "entityCode": "",
            "decimalCode": "&#986;",
            "hexCode": "&#x3da;"
        },
        {
            "symbol": "ϛ",
            "name": "Greek Small Letter Stigma",
            "entityCode": "",
            "decimalCode": "&#987;",
            "hexCode": "&#x3db;"
        },
        {
            "symbol": "Ϝ",
            "name": "Greek Letter Digamma",
            "entityCode": "",
            "decimalCode": "&#988;",
            "hexCode": "&#x3dc;"
        },
        {
            "symbol": "ϝ",
            "name": "Greek Small Letter Digamma",
            "entityCode": "",
            "decimalCode": "&#989;",
            "hexCode": "&#x3dd;"
        },
        {
            "symbol": "Ϟ",
            "name": "Greek Letter Koppa",
            "entityCode": "",
            "decimalCode": "&#990;",
            "hexCode": "&#x3de;"
        },
        {
            "symbol": "ϟ",
            "name": "Greek Small Letter Koppa",
            "entityCode": "",
            "decimalCode": "&#991;",
            "hexCode": "&#x3df;"
        },
        {
            "symbol": "Ϡ",
            "name": "Greek Letter Sampi",
            "entityCode": "",
            "decimalCode": "&#992;",
            "hexCode": "&#x3e0;"
        },
        {
            "symbol": "ϡ",
            "name": "Greek Small Letter Sampi",
            "entityCode": "",
            "decimalCode": "&#993;",
            "hexCode": "&#x3e1;"
        },
        {
            "symbol": "Ϣ",
            "name": "Coptic Capital Letter Shei",
            "entityCode": "",
            "decimalCode": "&#994;",
            "hexCode": "&#x3e2;"
        },
        {
            "symbol": "ϣ",
            "name": "Coptic Small Letter Shei",
            "entityCode": "",
            "decimalCode": "&#995;",
            "hexCode": "&#x3e3;"
        },
        {
            "symbol": "Ϥ",
            "name": "Coptic Capital Letter Fei",
            "entityCode": "",
            "decimalCode": "&#996;",
            "hexCode": "&#x3e4;"
        },
        {
            "symbol": "ϥ",
            "name": "Coptic Small Letter Fei",
            "entityCode": "",
            "decimalCode": "&#997;",
            "hexCode": "&#x3e5;"
        },
        {
            "symbol": "Ϧ",
            "name": "Coptic Capital Letter Khei",
            "entityCode": "",
            "decimalCode": "&#998;",
            "hexCode": "&#x3e6;"
        },
        {
            "symbol": "ϧ",
            "name": "Coptic Small Letter Khei",
            "entityCode": "",
            "decimalCode": "&#999;",
            "hexCode": "&#x3e7;"
        },
        {
            "symbol": "Ϩ",
            "name": "Coptic Capital Letter Hori",
            "entityCode": "",
            "decimalCode": "&#1000;",
            "hexCode": "&#x3e8;"
        },
        {
            "symbol": "ϩ",
            "name": "Coptic Small Letter Hori",
            "entityCode": "",
            "decimalCode": "&#1001;",
            "hexCode": "&#x3e9;"
        },
        {
            "symbol": "Ϫ",
            "name": "Coptic Capital Letter Gangia",
            "entityCode": "",
            "decimalCode": "&#1002;",
            "hexCode": "&#x3ea;"
        },
        {
            "symbol": "ϫ",
            "name": "Coptic Small Letter Gangia",
            "entityCode": "",
            "decimalCode": "&#1003;",
            "hexCode": "&#x3eb;"
        },
        {
            "symbol": "Ϭ",
            "name": "Coptic Capital Letter Shima",
            "entityCode": "",
            "decimalCode": "&#1004;",
            "hexCode": "&#x3ec;"
        },
        {
            "symbol": "ϭ",
            "name": "Coptic Small Letter Shima",
            "entityCode": "",
            "decimalCode": "&#1005;",
            "hexCode": "&#x3ed;"
        },
        {
            "symbol": "Ϯ",
            "name": "Coptic Capital Letter Dei",
            "entityCode": "",
            "decimalCode": "&#1006;",
            "hexCode": "&#x3ee;"
        },
        {
            "symbol": "ϯ",
            "name": "Coptic Small Letter Dei",
            "entityCode": "",
            "decimalCode": "&#1007;",
            "hexCode": "&#x3ef;"
        },
        {
            "symbol": "ϰ",
            "name": "Greek Kappa Symbol",
            "entityCode": "",
            "decimalCode": "&#1008;",
            "hexCode": "&#x3f0;"
        },
        {
            "symbol": "ϱ",
            "name": "Greek Rho Symbol",
            "entityCode": "",
            "decimalCode": "&#1009;",
            "hexCode": "&#x3f1;"
        },
        {
            "symbol": "ϲ",
            "name": "Greek Lunate Sigma Symbol",
            "entityCode": "",
            "decimalCode": "&#1010;",
            "hexCode": "&#x3f2;"
        },
        {
            "symbol": "ϳ",
            "name": "Greek Letter Yot",
            "entityCode": "",
            "decimalCode": "&#1011;",
            "hexCode": "&#x3f3;"
        },
        {
            "symbol": "ϴ",
            "name": "Greek Capital Theta Symbol",
            "entityCode": "",
            "decimalCode": "&#1012;",
            "hexCode": "&#x3f4;"
        },
        {
            "symbol": "ϵ",
            "name": "Greek Lunate Epsilon Symbol",
            "entityCode": "",
            "decimalCode": "&#1013;",
            "hexCode": "&#x3f5;"
        },
        {
            "symbol": "϶",
            "name": "Greek Reversed Lunate Epsilon Symbol",
            "entityCode": "",
            "decimalCode": "&#1014;",
            "hexCode": "&#x3f6;"
        },
        {
            "symbol": "Ϸ",
            "name": "Greek Capital Letter Sho",
            "entityCode": "",
            "decimalCode": "&#1015;",
            "hexCode": "&#x3f7;"
        },
        {
            "symbol": "ϸ",
            "name": "Greek Small Letter Sho",
            "entityCode": "",
            "decimalCode": "&#1016;",
            "hexCode": "&#x3f8;"
        },
        {
            "symbol": "Ϲ",
            "name": "Greek Capital Lunate Sigma Symbol",
            "entityCode": "",
            "decimalCode": "&#1017;",
            "hexCode": "&#x3f9;"
        },
        {
            "symbol": "Ϻ",
            "name": "Greek Capital Letter San",
            "entityCode": "",
            "decimalCode": "&#1018;",
            "hexCode": "&#x3fa;"
        },
        {
            "symbol": "ϻ",
            "name": "Greek Small Letter San",
            "entityCode": "",
            "decimalCode": "&#1019;",
            "hexCode": "&#x3fb;"
        },
        {
            "symbol": "ϼ",
            "name": "Greek Rho With Stroke Symbol",
            "entityCode": "",
            "decimalCode": "&#1020;",
            "hexCode": "&#x3fc;"
        },
        {
            "symbol": "Ͻ",
            "name": "Greek Capital Reversed Lunate Sigma Symbol",
            "entityCode": "",
            "decimalCode": "&#1021;",
            "hexCode": "&#x3fd;"
        },
        {
            "symbol": "Ͼ",
            "name": "Greek Capital Dotted Lunate Sigma Symbol",
            "entityCode": "",
            "decimalCode": "&#1022;",
            "hexCode": "&#x3fe;"
        },
        {
            "symbol": "Ͽ",
            "name": "Greek Capital Reversed Dotted Lunate Sigma Symbol",
            "entityCode": "",
            "decimalCode": "&#1023;",
            "hexCode": "&#x3ff;"
        }
    ],
    arrows: [
        {
            "symbol": "←",
            "name": "Leftwards Arrow",
            "entityCode": "&larr;",
            "decimalCode": "&#8592;",
            "hexCode": "&#x2190;"
        },
        {
            "symbol": "↑",
            "name": "Upwards Arrow",
            "entityCode": "&uarr;",
            "decimalCode": "&#8593;",
            "hexCode": "&#x2191;"
        },
        {
            "symbol": "→",
            "name": "Rightwards Arrow",
            "entityCode": "&rarr;",
            "decimalCode": "&#8594;",
            "hexCode": "&#x2192;"
        },
        {
            "symbol": "↓",
            "name": "Downwards Arrow",
            "entityCode": "&darr;",
            "decimalCode": "&#8595;",
            "hexCode": "&#x2193;"
        },
        {
            "symbol": "↔",
            "name": "Left Right Arrow",
            "entityCode": "&harr;",
            "decimalCode": "&#8596;",
            "hexCode": "&#x2194;"
        },
        {
            "symbol": "↕",
            "name": "Up Down Arrow",
            "entityCode": "",
            "decimalCode": "&#8597;",
            "hexCode": "&#x2195;"
        },
        {
            "symbol": "↖",
            "name": "North West Arrow",
            "entityCode": "",
            "decimalCode": "&#8598;",
            "hexCode": "&#x2196;"
        },
        {
            "symbol": "↗",
            "name": "North East Arrow",
            "entityCode": "",
            "decimalCode": "&#8599;",
            "hexCode": "&#x2197;"
        },
        {
            "symbol": "↘",
            "name": "South East Arrow",
            "entityCode": "",
            "decimalCode": "&#8600;",
            "hexCode": "&#x2198;"
        },
        {
            "symbol": "↙",
            "name": "South West Arrow",
            "entityCode": "",
            "decimalCode": "&#8601;",
            "hexCode": "&#x2199;"
        },
        {
            "symbol": "↚",
            "name": "Leftwards Arrow With Stroke",
            "entityCode": "",
            "decimalCode": "&#8602;",
            "hexCode": "&#x219a;"
        },
        {
            "symbol": "↛",
            "name": "Rightwards Arrow With Stroke",
            "entityCode": "",
            "decimalCode": "&#8603;",
            "hexCode": "&#x219b;"
        },
        {
            "symbol": "↜",
            "name": "Leftwards Wave Arrow",
            "entityCode": "",
            "decimalCode": "&#8604;",
            "hexCode": "&#x219c;"
        },
        {
            "symbol": "↝",
            "name": "Rightwards Wave Arrow",
            "entityCode": "",
            "decimalCode": "&#8605;",
            "hexCode": "&#x219d;"
        },
        {
            "symbol": "↞",
            "name": "Leftwards Two Headed Arrow",
            "entityCode": "",
            "decimalCode": "&#8606;",
            "hexCode": "&#x219e;"
        },
        {
            "symbol": "↟",
            "name": "Upwards Two Headed Arrow",
            "entityCode": "",
            "decimalCode": "&#8607;",
            "hexCode": "&#x219f;"
        },
        {
            "symbol": "↠",
            "name": "Rightwards Two Headed Arrow",
            "entityCode": "",
            "decimalCode": "&#8608;",
            "hexCode": "&#x21a0;"
        },
        {
            "symbol": "↡",
            "name": "Downwards Two Headed Arrow",
            "entityCode": "",
            "decimalCode": "&#8609;",
            "hexCode": "&#x21a1;"
        },
        {
            "symbol": "↢",
            "name": "Leftwards Arrow With Tail",
            "entityCode": "",
            "decimalCode": "&#8610;",
            "hexCode": "&#x21a2;"
        },
        {
            "symbol": "↣",
            "name": "Rightwards Arrow With Tail",
            "entityCode": "",
            "decimalCode": "&#8611;",
            "hexCode": "&#x21a3;"
        },
        {
            "symbol": "↤",
            "name": "Leftwards Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#8612;",
            "hexCode": "&#x21a4;"
        },
        {
            "symbol": "↥",
            "name": "Upwards Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#8613;",
            "hexCode": "&#x21a5;"
        },
        {
            "symbol": "↦",
            "name": "Rightwards Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#8614;",
            "hexCode": "&#x21a6;"
        },
        {
            "symbol": "↧",
            "name": "Downwards Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#8615;",
            "hexCode": "&#x21a7;"
        },
        {
            "symbol": "↨",
            "name": "Up Down Arrow With Base",
            "entityCode": "",
            "decimalCode": "&#8616;",
            "hexCode": "&#x21a8;"
        },
        {
            "symbol": "↩",
            "name": "Leftwards Arrow With Hook",
            "entityCode": "",
            "decimalCode": "&#8617;",
            "hexCode": "&#x21a9;"
        },
        {
            "symbol": "↪",
            "name": "Rightwards Arrow With Hook",
            "entityCode": "",
            "decimalCode": "&#8618;",
            "hexCode": "&#x21aa;"
        },
        {
            "symbol": "↫",
            "name": "Leftwards Arrow With Loop",
            "entityCode": "",
            "decimalCode": "&#8619;",
            "hexCode": "&#x21ab;"
        },
        {
            "symbol": "↬",
            "name": "Rightwards Arrow With Loop",
            "entityCode": "",
            "decimalCode": "&#8620;",
            "hexCode": "&#x21ac;"
        },
        {
            "symbol": "↭",
            "name": "Left Right Wave Arrow",
            "entityCode": "",
            "decimalCode": "&#8621;",
            "hexCode": "&#x21ad;"
        },
        {
            "symbol": "↮",
            "name": "Left Right Arrow With Stroke",
            "entityCode": "",
            "decimalCode": "&#8622;",
            "hexCode": "&#x21ae;"
        },
        {
            "symbol": "↯",
            "name": "Downwards Zigzag Arrow",
            "entityCode": "",
            "decimalCode": "&#8623;",
            "hexCode": "&#x21af;"
        },
        {
            "symbol": "↰",
            "name": "Upwards Arrow With Tip Leftwards",
            "entityCode": "",
            "decimalCode": "&#8624;",
            "hexCode": "&#x21b0;"
        },
        {
            "symbol": "↱",
            "name": "Upwards Arrow With Tip Rightwards",
            "entityCode": "",
            "decimalCode": "&#8625;",
            "hexCode": "&#x21b1;"
        },
        {
            "symbol": "↲",
            "name": "Downwards Arrow With Tip Leftwards",
            "entityCode": "",
            "decimalCode": "&#8626;",
            "hexCode": "&#x21b2;"
        },
        {
            "symbol": "↳",
            "name": "Downwards Arrow With Tip Rightwards",
            "entityCode": "",
            "decimalCode": "&#8627;",
            "hexCode": "&#x21b3;"
        },
        {
            "symbol": "↴",
            "name": "Rightwards Arrow With Corner Downwards",
            "entityCode": "",
            "decimalCode": "&#8628;",
            "hexCode": "&#x21b4;"
        },
        {
            "symbol": "↵",
            "name": "Downwards Arrow With Corner Leftwards",
            "entityCode": "&crarr;",
            "decimalCode": "&#8629;",
            "hexCode": "&#x21b5;"
        },
        {
            "symbol": "↶",
            "name": "Anticlockwise Top Semicircle Arrow",
            "entityCode": "",
            "decimalCode": "&#8630;",
            "hexCode": "&#x21b6;"
        },
        {
            "symbol": "↷",
            "name": "Clockwise Top Semicircle Arrow",
            "entityCode": "",
            "decimalCode": "&#8631;",
            "hexCode": "&#x21b7;"
        },
        {
            "symbol": "↸",
            "name": "North West Arrow To Long Bar",
            "entityCode": "",
            "decimalCode": "&#8632;",
            "hexCode": "&#x21b8;"
        },
        {
            "symbol": "↹",
            "name": "Leftwards Arrow To Bar Over Rightwards Arrow To Bar",
            "entityCode": "",
            "decimalCode": "&#8633;",
            "hexCode": "&#x21b9;"
        },
        {
            "symbol": "↺",
            "name": "Anticlockwise Open Circle Arrow",
            "entityCode": "",
            "decimalCode": "&#8634;",
            "hexCode": "&#x21ba;"
        },
        {
            "symbol": "↻",
            "name": "Clockwise Open Circle Arrow",
            "entityCode": "",
            "decimalCode": "&#8635;",
            "hexCode": "&#x21bb;"
        },
        {
            "symbol": "↼",
            "name": "Leftwards Harpoon With Barb Upwards",
            "entityCode": "",
            "decimalCode": "&#8636;",
            "hexCode": "&#x21bc;"
        },
        {
            "symbol": "↽",
            "name": "Leftwards Harpoon With Barb Downwards",
            "entityCode": "",
            "decimalCode": "&#8637;",
            "hexCode": "&#x21bd;"
        },
        {
            "symbol": "↾",
            "name": "Upwards Harpoon With Barb Rightwards",
            "entityCode": "",
            "decimalCode": "&#8638;",
            "hexCode": "&#x21be;"
        },
        {
            "symbol": "↿",
            "name": "Upwards Harpoon With Barb Leftwards",
            "entityCode": "",
            "decimalCode": "&#8639;",
            "hexCode": "&#x21bf;"
        },
        {
            "symbol": "⇀",
            "name": "Rightwards Harpoon With Barb Upwards",
            "entityCode": "",
            "decimalCode": "&#8640;",
            "hexCode": "&#x21c0;"
        },
        {
            "symbol": "⇁",
            "name": "Rightwards Harpoon With Barb Downwards",
            "entityCode": "",
            "decimalCode": "&#8641;",
            "hexCode": "&#x21c1;"
        },
        {
            "symbol": "⇂",
            "name": "Downwards Harpoon With Barb Rightwards",
            "entityCode": "",
            "decimalCode": "&#8642;",
            "hexCode": "&#x21c2;"
        },
        {
            "symbol": "⇃",
            "name": "Downwards Harpoon With Barb Leftwards",
            "entityCode": "",
            "decimalCode": "&#8643;",
            "hexCode": "&#x21c3;"
        },
        {
            "symbol": "⇄",
            "name": "Rightwards Arrow Over Leftwards Arrow",
            "entityCode": "",
            "decimalCode": "&#8644;",
            "hexCode": "&#x21c4;"
        },
        {
            "symbol": "⇅",
            "name": "Upwards Arrow Leftwards Of Downwards Arrow",
            "entityCode": "",
            "decimalCode": "&#8645;",
            "hexCode": "&#x21c5;"
        },
        {
            "symbol": "⇆",
            "name": "Leftwards Arrow Over Rightwards Arrow",
            "entityCode": "",
            "decimalCode": "&#8646;",
            "hexCode": "&#x21c6;"
        },
        {
            "symbol": "⇇",
            "name": "Leftwards Paired Arrows",
            "entityCode": "",
            "decimalCode": "&#8647;",
            "hexCode": "&#x21c7;"
        },
        {
            "symbol": "⇈",
            "name": "Upwards Paired Arrows",
            "entityCode": "",
            "decimalCode": "&#8648;",
            "hexCode": "&#x21c8;"
        },
        {
            "symbol": "⇉",
            "name": "Rightwards Paired Arrows",
            "entityCode": "",
            "decimalCode": "&#8649;",
            "hexCode": "&#x21c9;"
        },
        {
            "symbol": "⇊",
            "name": "Downwards Paired Arrows",
            "entityCode": "",
            "decimalCode": "&#8650;",
            "hexCode": "&#x21ca;"
        },
        {
            "symbol": "⇋",
            "name": "Leftwards Harpoon Over Rightwards Harpoon",
            "entityCode": "",
            "decimalCode": "&#8651;",
            "hexCode": "&#x21cb;"
        },
        {
            "symbol": "⇌",
            "name": "Rightwards Harpoon Over Leftwards Harpoon",
            "entityCode": "",
            "decimalCode": "&#8652;",
            "hexCode": "&#x21cc;"
        },
        {
            "symbol": "⇍",
            "name": "Leftwards Double Arrow With Stroke",
            "entityCode": "",
            "decimalCode": "&#8653;",
            "hexCode": "&#x21cd;"
        },
        {
            "symbol": "⇎",
            "name": "Left Right Double Arrow With Stroke",
            "entityCode": "",
            "decimalCode": "&#8654;",
            "hexCode": "&#x21ce;"
        },
        {
            "symbol": "⇏",
            "name": "Rightwards Double Arrow With Stroke",
            "entityCode": "",
            "decimalCode": "&#8655;",
            "hexCode": "&#x21cf;"
        },
        {
            "symbol": "⇐",
            "name": "Leftwards Double Arrow",
            "entityCode": "&lArr;",
            "decimalCode": "&#8656;",
            "hexCode": "&#x21d0;"
        },
        {
            "symbol": "⇑",
            "name": "Upwards Double Arrow",
            "entityCode": "&uArr;",
            "decimalCode": "&#8657;",
            "hexCode": "&#x21d1;"
        },
        {
            "symbol": "⇒",
            "name": "Rightwards Double Arrow",
            "entityCode": "&rArr;",
            "decimalCode": "&#8658;",
            "hexCode": "&#x21d2;"
        },
        {
            "symbol": "⇓",
            "name": "Downwards Double Arrow",
            "entityCode": "&dArr;",
            "decimalCode": "&#8659;",
            "hexCode": "&#x21d3;"
        },
        {
            "symbol": "⇔",
            "name": "Left Right Double Arrow",
            "entityCode": "&hArr;",
            "decimalCode": "&#8660;",
            "hexCode": "&#x21d4;"
        },
        {
            "symbol": "⇕",
            "name": "Up Down Double Arrow",
            "entityCode": "",
            "decimalCode": "&#8661;",
            "hexCode": "&#x21d5;"
        },
        {
            "symbol": "⇖",
            "name": "North West Double Arrow",
            "entityCode": "",
            "decimalCode": "&#8662;",
            "hexCode": "&#x21d6;"
        },
        {
            "symbol": "⇗",
            "name": "North East Double Arrow",
            "entityCode": "",
            "decimalCode": "&#8663;",
            "hexCode": "&#x21d7;"
        },
        {
            "symbol": "⇘",
            "name": "South East Double Arrow",
            "entityCode": "",
            "decimalCode": "&#8664;",
            "hexCode": "&#x21d8;"
        },
        {
            "symbol": "⇙",
            "name": "South West Double Arrow",
            "entityCode": "",
            "decimalCode": "&#8665;",
            "hexCode": "&#x21d9;"
        },
        {
            "symbol": "⇚",
            "name": "Leftwards Triple Arrow",
            "entityCode": "",
            "decimalCode": "&#8666;",
            "hexCode": "&#x21da;"
        },
        {
            "symbol": "⇛",
            "name": "Rightwards Triple Arrow",
            "entityCode": "",
            "decimalCode": "&#8667;",
            "hexCode": "&#x21db;"
        },
        {
            "symbol": "⇜",
            "name": "Leftwards Squiggle Arrow",
            "entityCode": "",
            "decimalCode": "&#8668;",
            "hexCode": "&#x21dc;"
        },
        {
            "symbol": "⇝",
            "name": "Rightwards Squiggle Arrow",
            "entityCode": "",
            "decimalCode": "&#8669;",
            "hexCode": "&#x21dd;"
        },
        {
            "symbol": "⇞",
            "name": "Upwards Arrow With Double Stroke",
            "entityCode": "",
            "decimalCode": "&#8670;",
            "hexCode": "&#x21de;"
        },
        {
            "symbol": "⇟",
            "name": "Downwards Arrow With Double Stroke",
            "entityCode": "",
            "decimalCode": "&#8671;",
            "hexCode": "&#x21df;"
        },
        {
            "symbol": "⇠",
            "name": "Leftwards Dashed Arrow",
            "entityCode": "",
            "decimalCode": "&#8672;",
            "hexCode": "&#x21e0;"
        },
        {
            "symbol": "⇡",
            "name": "Upwards Dashed Arrow",
            "entityCode": "",
            "decimalCode": "&#8673;",
            "hexCode": "&#x21e1;"
        },
        {
            "symbol": "⇢",
            "name": "Rightwards Dashed Arrow",
            "entityCode": "",
            "decimalCode": "&#8674;",
            "hexCode": "&#x21e2;"
        },
        {
            "symbol": "⇣",
            "name": "Downwards Dashed Arrow",
            "entityCode": "",
            "decimalCode": "&#8675;",
            "hexCode": "&#x21e3;"
        },
        {
            "symbol": "⇤",
            "name": "Leftwards Arrow To Bar",
            "entityCode": "",
            "decimalCode": "&#8676;",
            "hexCode": "&#x21e4;"
        },
        {
            "symbol": "⇥",
            "name": "Rightwards Arrow To Bar",
            "entityCode": "",
            "decimalCode": "&#8677;",
            "hexCode": "&#x21e5;"
        },
        {
            "symbol": "⇦",
            "name": "Leftwards White Arrow",
            "entityCode": "",
            "decimalCode": "&#8678;",
            "hexCode": "&#x21e6;"
        },
        {
            "symbol": "⇧",
            "name": "Upwards White Arrow",
            "entityCode": "",
            "decimalCode": "&#8679;",
            "hexCode": "&#x21e7;"
        },
        {
            "symbol": "⇨",
            "name": "Rightwards White Arrow",
            "entityCode": "",
            "decimalCode": "&#8680;",
            "hexCode": "&#x21e8;"
        },
        {
            "symbol": "⇩",
            "name": "Downwards White Arrow",
            "entityCode": "",
            "decimalCode": "&#8681;",
            "hexCode": "&#x21e9;"
        },
        {
            "symbol": "⇪",
            "name": "Upwards White Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#8682;",
            "hexCode": "&#x21ea;"
        },
        {
            "symbol": "⇫",
            "name": "Upwards White Arrow On Pedestal",
            "entityCode": "",
            "decimalCode": "&#8683;",
            "hexCode": "&#x21eb;"
        },
        {
            "symbol": "⇬",
            "name": "Upwards White Arrow On Pedestal With Horizontal Bar",
            "entityCode": "",
            "decimalCode": "&#8684;",
            "hexCode": "&#x21ec;"
        },
        {
            "symbol": "⇭",
            "name": "Upwards White Arrow On Pedestal With Vertical Bar",
            "entityCode": "",
            "decimalCode": "&#8685;",
            "hexCode": "&#x21ed;"
        },
        {
            "symbol": "⇮",
            "name": "Upwards White Double Arrow",
            "entityCode": "",
            "decimalCode": "&#8686;",
            "hexCode": "&#x21ee;"
        },
        {
            "symbol": "⇯",
            "name": "Upwards White Double Arrow On Pedestal",
            "entityCode": "",
            "decimalCode": "&#8687;",
            "hexCode": "&#x21ef;"
        },
        {
            "symbol": "⇰",
            "name": "Rightwards White Arrow From Wall",
            "entityCode": "",
            "decimalCode": "&#8688;",
            "hexCode": "&#x21f0;"
        },
        {
            "symbol": "⇱",
            "name": "North West Arrow To Corner",
            "entityCode": "",
            "decimalCode": "&#8689;",
            "hexCode": "&#x21f1;"
        },
        {
            "symbol": "⇲",
            "name": "South East Arrow To Corner",
            "entityCode": "",
            "decimalCode": "&#8690;",
            "hexCode": "&#x21f2;"
        },
        {
            "symbol": "⇳",
            "name": "Up Down White Arrow",
            "entityCode": "",
            "decimalCode": "&#8691;",
            "hexCode": "&#x21f3;"
        },
        {
            "symbol": "⇴",
            "name": "Right Arrow With Small Circle",
            "entityCode": "",
            "decimalCode": "&#8692;",
            "hexCode": "&#x21f4;"
        },
        {
            "symbol": "⇵",
            "name": "Downwards Arrow Leftwards Of Upwards Arrow",
            "entityCode": "",
            "decimalCode": "&#8693;",
            "hexCode": "&#x21f5;"
        },
        {
            "symbol": "⇶",
            "name": "Three Rightwards Arrows",
            "entityCode": "",
            "decimalCode": "&#8694;",
            "hexCode": "&#x21f6;"
        },
        {
            "symbol": "⇷",
            "name": "Leftwards Arrow With Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#8695;",
            "hexCode": "&#x21f7;"
        },
        {
            "symbol": "⇸",
            "name": "Rightwards Arrow With Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#8696;",
            "hexCode": "&#x21f8;"
        },
        {
            "symbol": "⇹",
            "name": "Left Right Arrow With Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#8697;",
            "hexCode": "&#x21f9;"
        },
        {
            "symbol": "⇺",
            "name": "Leftwards Arrow With Double Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#8698;",
            "hexCode": "&#x21fa;"
        },
        {
            "symbol": "⇻",
            "name": "Rightwards Arrow With Double Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#8699;",
            "hexCode": "&#x21fb;"
        },
        {
            "symbol": "⇼",
            "name": "Left Right Arrow With Double Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#8700;",
            "hexCode": "&#x21fc;"
        },
        {
            "symbol": "⇽",
            "name": "Leftwards Open-Headed Arrow",
            "entityCode": "",
            "decimalCode": "&#8701;",
            "hexCode": "&#x21fd;"
        },
        {
            "symbol": "⇾",
            "name": "Rightwards Open-Headed Arrow",
            "entityCode": "",
            "decimalCode": "&#8702;",
            "hexCode": "&#x21fe;"
        },
        {
            "symbol": "⇿",
            "name": "Left Right Open-Headed Arrow",
            "entityCode": "",
            "decimalCode": "&#8703;",
            "hexCode": "&#x21ff;"
        }
    ],
    shapes: [
        {
            "symbol": "■",
            "name": "Black Square",
            "entityCode": "",
            "decimalCode": "&#9632;",
            "hexCode": "&#x25a0;"
        },
        {
            "symbol": "□",
            "name": "White Square",
            "entityCode": "",
            "decimalCode": "&#9633;",
            "hexCode": "&#x25a1;"
        },
        {
            "symbol": "▢",
            "name": "White Square With Rounded Corners",
            "entityCode": "",
            "decimalCode": "&#9634;",
            "hexCode": "&#x25a2;"
        },
        {
            "symbol": "▣",
            "name": "White Square Containing Black Small Square",
            "entityCode": "",
            "decimalCode": "&#9635;",
            "hexCode": "&#x25a3;"
        },
        {
            "symbol": "▤",
            "name": "Square With Horizontal Fill",
            "entityCode": "",
            "decimalCode": "&#9636;",
            "hexCode": "&#x25a4;"
        },
        {
            "symbol": "▥",
            "name": "Square With Vertical Fill",
            "entityCode": "",
            "decimalCode": "&#9637;",
            "hexCode": "&#x25a5;"
        },
        {
            "symbol": "▦",
            "name": "Square With Orthogonal Crosshatch Fill",
            "entityCode": "",
            "decimalCode": "&#9638;",
            "hexCode": "&#x25a6;"
        },
        {
            "symbol": "▧",
            "name": "Square With Upper Left To Lower Right Fill",
            "entityCode": "",
            "decimalCode": "&#9639;",
            "hexCode": "&#x25a7;"
        },
        {
            "symbol": "▨",
            "name": "Square With Upper Right To Lower Left Fill",
            "entityCode": "",
            "decimalCode": "&#9640;",
            "hexCode": "&#x25a8;"
        },
        {
            "symbol": "▩",
            "name": "Square With Diagonal Crosshatch Fill",
            "entityCode": "",
            "decimalCode": "&#9641;",
            "hexCode": "&#x25a9;"
        },
        {
            "symbol": "▪",
            "name": "Black Small Square",
            "entityCode": "",
            "decimalCode": "&#9642;",
            "hexCode": "&#x25aa;"
        },
        {
            "symbol": "▫",
            "name": "White Small Square",
            "entityCode": "",
            "decimalCode": "&#9643;",
            "hexCode": "&#x25ab;"
        },
        {
            "symbol": "▬",
            "name": "Black Rectangle",
            "entityCode": "",
            "decimalCode": "&#9644;",
            "hexCode": "&#x25ac;"
        },
        {
            "symbol": "▭",
            "name": "White Rectangle",
            "entityCode": "",
            "decimalCode": "&#9645;",
            "hexCode": "&#x25ad;"
        },
        {
            "symbol": "▮",
            "name": "Black Vertical Rectangle",
            "entityCode": "",
            "decimalCode": "&#9646;",
            "hexCode": "&#x25ae;"
        },
        {
            "symbol": "▯",
            "name": "White Vertical Rectangle",
            "entityCode": "",
            "decimalCode": "&#9647;",
            "hexCode": "&#x25af;"
        },
        {
            "symbol": "▰",
            "name": "Black Parallelogram",
            "entityCode": "",
            "decimalCode": "&#9648;",
            "hexCode": "&#x25b0;"
        },
        {
            "symbol": "▱",
            "name": "White Parallelogram",
            "entityCode": "",
            "decimalCode": "&#9649;",
            "hexCode": "&#x25b1;"
        },
        {
            "symbol": "▲",
            "name": "Black Up-Pointing Triangle",
            "entityCode": "",
            "decimalCode": "&#9650;",
            "hexCode": "&#x25b2;"
        },
        {
            "symbol": "△",
            "name": "White Up-Pointing Triangle",
            "entityCode": "",
            "decimalCode": "&#9651;",
            "hexCode": "&#x25b3;"
        },
        {
            "symbol": "▴",
            "name": "Black Up-Pointing Small Triangle",
            "entityCode": "",
            "decimalCode": "&#9652;",
            "hexCode": "&#x25b4;"
        },
        {
            "symbol": "▵",
            "name": "White Up-Pointing Small Triangle",
            "entityCode": "",
            "decimalCode": "&#9653;",
            "hexCode": "&#x25b5;"
        },
        {
            "symbol": "▶",
            "name": "Black Right-Pointing Triangle",
            "entityCode": "",
            "decimalCode": "&#9654;",
            "hexCode": "&#x25b6;"
        },
        {
            "symbol": "▷",
            "name": "White Right-Pointing Triangle",
            "entityCode": "",
            "decimalCode": "&#9655;",
            "hexCode": "&#x25b7;"
        },
        {
            "symbol": "▸",
            "name": "Black Right-Pointing Small Triangle",
            "entityCode": "",
            "decimalCode": "&#9656;",
            "hexCode": "&#x25b8;"
        },
        {
            "symbol": "▹",
            "name": "White Right-Pointing Small Triangle",
            "entityCode": "",
            "decimalCode": "&#9657;",
            "hexCode": "&#x25b9;"
        },
        {
            "symbol": "►",
            "name": "Black Right-Pointing Pointer",
            "entityCode": "",
            "decimalCode": "&#9658;",
            "hexCode": "&#x25ba;"
        },
        {
            "symbol": "▻",
            "name": "White Right-Pointing Pointer",
            "entityCode": "",
            "decimalCode": "&#9659;",
            "hexCode": "&#x25bb;"
        },
        {
            "symbol": "▼",
            "name": "Black Down-Pointing Triangle",
            "entityCode": "",
            "decimalCode": "&#9660;",
            "hexCode": "&#x25bc;"
        },
        {
            "symbol": "▽",
            "name": "White Down-Pointing Triangle",
            "entityCode": "",
            "decimalCode": "&#9661;",
            "hexCode": "&#x25bd;"
        },
        {
            "symbol": "▾",
            "name": "Black Down-Pointing Small Triangle",
            "entityCode": "",
            "decimalCode": "&#9662;",
            "hexCode": "&#x25be;"
        },
        {
            "symbol": "▿",
            "name": "White Down-Pointing Small Triangle",
            "entityCode": "",
            "decimalCode": "&#9663;",
            "hexCode": "&#x25bf;"
        },
        {
            "symbol": "◀",
            "name": "Black Left-Pointing Triangle",
            "entityCode": "",
            "decimalCode": "&#9664;",
            "hexCode": "&#x25c0;"
        },
        {
            "symbol": "◁",
            "name": "White Left-Pointing Triangle",
            "entityCode": "",
            "decimalCode": "&#9665;",
            "hexCode": "&#x25c1;"
        },
        {
            "symbol": "◂",
            "name": "Black Left-Pointing Small Triangle",
            "entityCode": "",
            "decimalCode": "&#9666;",
            "hexCode": "&#x25c2;"
        },
        {
            "symbol": "◃",
            "name": "White Left-Pointing Small Triangle",
            "entityCode": "",
            "decimalCode": "&#9667;",
            "hexCode": "&#x25c3;"
        },
        {
            "symbol": "◄",
            "name": "Black Left-Pointing Pointer",
            "entityCode": "",
            "decimalCode": "&#9668;",
            "hexCode": "&#x25c4;"
        },
        {
            "symbol": "◅",
            "name": "White Left-Pointing Pointer",
            "entityCode": "",
            "decimalCode": "&#9669;",
            "hexCode": "&#x25c5;"
        },
        {
            "symbol": "◆",
            "name": "Black Diamond",
            "entityCode": "",
            "decimalCode": "&#9670;",
            "hexCode": "&#x25c6;"
        },
        {
            "symbol": "◇",
            "name": "White Diamond",
            "entityCode": "",
            "decimalCode": "&#9671;",
            "hexCode": "&#x25c7;"
        },
        {
            "symbol": "◈",
            "name": "White Diamond Containing Black Small Diamond",
            "entityCode": "",
            "decimalCode": "&#9672;",
            "hexCode": "&#x25c8;"
        },
        {
            "symbol": "◉",
            "name": "Fisheye",
            "entityCode": "",
            "decimalCode": "&#9673;",
            "hexCode": "&#x25c9;"
        },
        {
            "symbol": "◊",
            "name": "Lozenge",
            "entityCode": "&loz;",
            "decimalCode": "&#9674;",
            "hexCode": "&#x25ca;"
        },
        {
            "symbol": "○",
            "name": "White Circle",
            "entityCode": "",
            "decimalCode": "&#9675;",
            "hexCode": "&#x25cb;"
        },
        {
            "symbol": "◌",
            "name": "Dotted Circle",
            "entityCode": "",
            "decimalCode": "&#9676;",
            "hexCode": "&#x25cc;"
        },
        {
            "symbol": "◍",
            "name": "Circle With Vertical Fill",
            "entityCode": "",
            "decimalCode": "&#9677;",
            "hexCode": "&#x25cd;"
        },
        {
            "symbol": "◎",
            "name": "Bullseye",
            "entityCode": "",
            "decimalCode": "&#9678;",
            "hexCode": "&#x25ce;"
        },
        {
            "symbol": "●",
            "name": "Black Circle",
            "entityCode": "",
            "decimalCode": "&#9679;",
            "hexCode": "&#x25cf;"
        },
        {
            "symbol": "◐",
            "name": "Circle With Left Half Black",
            "entityCode": "",
            "decimalCode": "&#9680;",
            "hexCode": "&#x25d0;"
        },
        {
            "symbol": "◑",
            "name": "Circle With Right Half Black",
            "entityCode": "",
            "decimalCode": "&#9681;",
            "hexCode": "&#x25d1;"
        },
        {
            "symbol": "◒",
            "name": "Circle With Lower Half Black",
            "entityCode": "",
            "decimalCode": "&#9682;",
            "hexCode": "&#x25d2;"
        },
        {
            "symbol": "◓",
            "name": "Circle With Upper Half Black",
            "entityCode": "",
            "decimalCode": "&#9683;",
            "hexCode": "&#x25d3;"
        },
        {
            "symbol": "◔",
            "name": "Circle With Upper Right Quadrant Black",
            "entityCode": "",
            "decimalCode": "&#9684;",
            "hexCode": "&#x25d4;"
        },
        {
            "symbol": "◕",
            "name": "Circle With All But Upper Left Quadrant Black",
            "entityCode": "",
            "decimalCode": "&#9685;",
            "hexCode": "&#x25d5;"
        },
        {
            "symbol": "◖",
            "name": "Left Half Black Circle",
            "entityCode": "",
            "decimalCode": "&#9686;",
            "hexCode": "&#x25d6;"
        },
        {
            "symbol": "◗",
            "name": "Right Half Black Circle",
            "entityCode": "",
            "decimalCode": "&#9687;",
            "hexCode": "&#x25d7;"
        },
        {
            "symbol": "◘",
            "name": "Inverse Bullet",
            "entityCode": "",
            "decimalCode": "&#9688;",
            "hexCode": "&#x25d8;"
        },
        {
            "symbol": "◙",
            "name": "Inverse White Circle",
            "entityCode": "",
            "decimalCode": "&#9689;",
            "hexCode": "&#x25d9;"
        },
        {
            "symbol": "◚",
            "name": "Upper Half Inverse White Circle",
            "entityCode": "",
            "decimalCode": "&#9690;",
            "hexCode": "&#x25da;"
        },
        {
            "symbol": "◛",
            "name": "Lower Half Inverse White Circle",
            "entityCode": "",
            "decimalCode": "&#9691;",
            "hexCode": "&#x25db;"
        },
        {
            "symbol": "◜",
            "name": "Upper Left Quadrant Circular Arc",
            "entityCode": "",
            "decimalCode": "&#9692;",
            "hexCode": "&#x25dc;"
        },
        {
            "symbol": "◝",
            "name": "Upper Right Quadrant Circular Arc",
            "entityCode": "",
            "decimalCode": "&#9693;",
            "hexCode": "&#x25dd;"
        },
        {
            "symbol": "◞",
            "name": "Lower Right Quadrant Circular Arc",
            "entityCode": "",
            "decimalCode": "&#9694;",
            "hexCode": "&#x25de;"
        },
        {
            "symbol": "◟",
            "name": "Lower Left Quadrant Circular Arc",
            "entityCode": "",
            "decimalCode": "&#9695;",
            "hexCode": "&#x25df;"
        },
        {
            "symbol": "◠",
            "name": "Upper Half Circle",
            "entityCode": "",
            "decimalCode": "&#9696;",
            "hexCode": "&#x25e0;"
        },
        {
            "symbol": "◡",
            "name": "Lower Half Circle",
            "entityCode": "",
            "decimalCode": "&#9697;",
            "hexCode": "&#x25e1;"
        },
        {
            "symbol": "◢",
            "name": "Black Lower Right Triangle",
            "entityCode": "",
            "decimalCode": "&#9698;",
            "hexCode": "&#x25e2;"
        },
        {
            "symbol": "◣",
            "name": "Black Lower Left Triangle",
            "entityCode": "",
            "decimalCode": "&#9699;",
            "hexCode": "&#x25e3;"
        },
        {
            "symbol": "◤",
            "name": "Black Upper Left Triangle",
            "entityCode": "",
            "decimalCode": "&#9700;",
            "hexCode": "&#x25e4;"
        },
        {
            "symbol": "◥",
            "name": "Black Upper Right Triangle",
            "entityCode": "",
            "decimalCode": "&#9701;",
            "hexCode": "&#x25e5;"
        },
        {
            "symbol": "◦",
            "name": "White Bullet",
            "entityCode": "",
            "decimalCode": "&#9702;",
            "hexCode": "&#x25e6;"
        },
        {
            "symbol": "◧",
            "name": "Square With Left Half Black",
            "entityCode": "",
            "decimalCode": "&#9703;",
            "hexCode": "&#x25e7;"
        },
        {
            "symbol": "◨",
            "name": "Square With Right Half Black",
            "entityCode": "",
            "decimalCode": "&#9704;",
            "hexCode": "&#x25e8;"
        },
        {
            "symbol": "◩",
            "name": "Square With Upper Left Diagonal Half Black",
            "entityCode": "",
            "decimalCode": "&#9705;",
            "hexCode": "&#x25e9;"
        },
        {
            "symbol": "◪",
            "name": "Square With Lower Right Diagonal Half Black",
            "entityCode": "",
            "decimalCode": "&#9706;",
            "hexCode": "&#x25ea;"
        },
        {
            "symbol": "◫",
            "name": "White Square With Vertical Bisecting Line",
            "entityCode": "",
            "decimalCode": "&#9707;",
            "hexCode": "&#x25eb;"
        },
        {
            "symbol": "◬",
            "name": "White Up-Pointing Triangle With Dot",
            "entityCode": "",
            "decimalCode": "&#9708;",
            "hexCode": "&#x25ec;"
        },
        {
            "symbol": "◭",
            "name": "Up-Pointing Triangle With Left Half Black",
            "entityCode": "",
            "decimalCode": "&#9709;",
            "hexCode": "&#x25ed;"
        },
        {
            "symbol": "◮",
            "name": "Up-Pointing Triangle With Right Half Black",
            "entityCode": "",
            "decimalCode": "&#9710;",
            "hexCode": "&#x25ee;"
        },
        {
            "symbol": "◯",
            "name": "Large Circle",
            "entityCode": "",
            "decimalCode": "&#9711;",
            "hexCode": "&#x25ef;"
        },
        {
            "symbol": "◰",
            "name": "White Square With Upper Left Quadrant",
            "entityCode": "",
            "decimalCode": "&#9712;",
            "hexCode": "&#x25f0;"
        },
        {
            "symbol": "◱",
            "name": "White Square With Lower Left Quadrant",
            "entityCode": "",
            "decimalCode": "&#9713;",
            "hexCode": "&#x25f1;"
        },
        {
            "symbol": "◲",
            "name": "White Square With Lower Right Quadrant",
            "entityCode": "",
            "decimalCode": "&#9714;",
            "hexCode": "&#x25f2;"
        },
        {
            "symbol": "◳",
            "name": "White Square With Upper Right Quadrant",
            "entityCode": "",
            "decimalCode": "&#9715;",
            "hexCode": "&#x25f3;"
        },
        {
            "symbol": "◴",
            "name": "White Circle With Upper Left Quadrant",
            "entityCode": "",
            "decimalCode": "&#9716;",
            "hexCode": "&#x25f4;"
        },
        {
            "symbol": "◵",
            "name": "White Circle With Lower Left Quadrant",
            "entityCode": "",
            "decimalCode": "&#9717;",
            "hexCode": "&#x25f5;"
        },
        {
            "symbol": "◶",
            "name": "White Circle With Lower Right Quadrant",
            "entityCode": "",
            "decimalCode": "&#9718;",
            "hexCode": "&#x25f6;"
        },
        {
            "symbol": "◷",
            "name": "White Circle With Upper Right Quadrant",
            "entityCode": "",
            "decimalCode": "&#9719;",
            "hexCode": "&#x25f7;"
        },
        {
            "symbol": "◸",
            "name": "Upper Left Triangle",
            "entityCode": "",
            "decimalCode": "&#9720;",
            "hexCode": "&#x25f8;"
        },
        {
            "symbol": "◹",
            "name": "Upper Right Triangle",
            "entityCode": "",
            "decimalCode": "&#9721;",
            "hexCode": "&#x25f9;"
        },
        {
            "symbol": "◺",
            "name": "Lower Left Triangle",
            "entityCode": "",
            "decimalCode": "&#9722;",
            "hexCode": "&#x25fa;"
        },
        {
            "symbol": "◻",
            "name": "White Medium Square",
            "entityCode": "",
            "decimalCode": "&#9723;",
            "hexCode": "&#x25fb;"
        },
        {
            "symbol": "◼",
            "name": "Black Medium Square",
            "entityCode": "",
            "decimalCode": "&#9724;",
            "hexCode": "&#x25fc;"
        },
        {
            "symbol": "◽",
            "name": "White Medium Small Square",
            "entityCode": "",
            "decimalCode": "&#9725;",
            "hexCode": "&#x25fd;"
        },
        {
            "symbol": "◾",
            "name": "Black Medium Small Square",
            "entityCode": "",
            "decimalCode": "&#9726;",
            "hexCode": "&#x25fe;"
        },
        {
            "symbol": "◿",
            "name": "Lower Right Triangle",
            "entityCode": "",
            "decimalCode": "&#9727;",
            "hexCode": "&#x25ff;"
        }
    ],
    arrows_supplemental_a: [
        {
            "symbol": "⟰",
            "name": "Upwards Quadruple Arrow",
            "entityCode": "",
            "decimalCode": "&#10224;",
            "hexCode": "&#x27f0;"
        },
        {
            "symbol": "⟱",
            "name": "Downwards Quadruple Arrow",
            "entityCode": "",
            "decimalCode": "&#10225;",
            "hexCode": "&#x27f1;"
        },
        {
            "symbol": "⟲",
            "name": "Anticlockwise Gapped Circle Arrow",
            "entityCode": "",
            "decimalCode": "&#10226;",
            "hexCode": "&#x27f2;"
        },
        {
            "symbol": "⟳",
            "name": "Clockwise Gapped Circle Arrow",
            "entityCode": "",
            "decimalCode": "&#10227;",
            "hexCode": "&#x27f3;"
        },
        {
            "symbol": "⟴",
            "name": "Right Arrow With Circled Plus",
            "entityCode": "",
            "decimalCode": "&#10228;",
            "hexCode": "&#x27f4;"
        },
        {
            "symbol": "⟵",
            "name": "Long Leftwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10229;",
            "hexCode": "&#x27f5;"
        },
        {
            "symbol": "⟶",
            "name": "Long Rightwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10230;",
            "hexCode": "&#x27f6;"
        },
        {
            "symbol": "⟷",
            "name": "Long Left Right Arrow",
            "entityCode": "",
            "decimalCode": "&#10231;",
            "hexCode": "&#x27f7;"
        },
        {
            "symbol": "⟸",
            "name": "Long Leftwards Double Arrow",
            "entityCode": "",
            "decimalCode": "&#10232;",
            "hexCode": "&#x27f8;"
        },
        {
            "symbol": "⟹",
            "name": "Long Rightwards Double Arrow",
            "entityCode": "",
            "decimalCode": "&#10233;",
            "hexCode": "&#x27f9;"
        },
        {
            "symbol": "⟺",
            "name": "Long Left Right Double Arrow",
            "entityCode": "",
            "decimalCode": "&#10234;",
            "hexCode": "&#x27fa;"
        },
        {
            "symbol": "⟻",
            "name": "Long Leftwards Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#10235;",
            "hexCode": "&#x27fb;"
        },
        {
            "symbol": "⟼",
            "name": "Long Rightwards Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#10236;",
            "hexCode": "&#x27fc;"
        },
        {
            "symbol": "⟽",
            "name": "Long Leftwards Double Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#10237;",
            "hexCode": "&#x27fd;"
        },
        {
            "symbol": "⟾",
            "name": "Long Rightwards Double Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#10238;",
            "hexCode": "&#x27fe;"
        },
        {
            "symbol": "⟿",
            "name": "Long Rightwards Squiggle Arrow",
            "entityCode": "",
            "decimalCode": "&#10239;",
            "hexCode": "&#x27ff;"
        }
    ],
    arrows_supplemental_b: [
        {
            "symbol": "⤀",
            "name": "Rightwards Two-Headed Arrow With Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#10496;",
            "hexCode": "&#x2900;"
        },
        {
            "symbol": "⤁",
            "name": "Rightwards Two-Headed Arrow With Double Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#10497;",
            "hexCode": "&#x2901;"
        },
        {
            "symbol": "⤂",
            "name": "Leftwards Double Arrow With Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#10498;",
            "hexCode": "&#x2902;"
        },
        {
            "symbol": "⤃",
            "name": "Rightwards Double Arrow With Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#10499;",
            "hexCode": "&#x2903;"
        },
        {
            "symbol": "⤄",
            "name": "Left Right Double Arrow With Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#10500;",
            "hexCode": "&#x2904;"
        },
        {
            "symbol": "⤅",
            "name": "Rightwards Two-Headed Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#10501;",
            "hexCode": "&#x2905;"
        },
        {
            "symbol": "⤆",
            "name": "Leftwards Double Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#10502;",
            "hexCode": "&#x2906;"
        },
        {
            "symbol": "⤇",
            "name": "Rightwards Double Arrow From Bar",
            "entityCode": "",
            "decimalCode": "&#10503;",
            "hexCode": "&#x2907;"
        },
        {
            "symbol": "⤈",
            "name": "Downwards Arrow With Horizontal Stroke",
            "entityCode": "",
            "decimalCode": "&#10504;",
            "hexCode": "&#x2908;"
        },
        {
            "symbol": "⤉",
            "name": "Upwards Arrow With Horizontal Stroke",
            "entityCode": "",
            "decimalCode": "&#10505;",
            "hexCode": "&#x2909;"
        },
        {
            "symbol": "⤊",
            "name": "Upwards Triple Arrow",
            "entityCode": "",
            "decimalCode": "&#10506;",
            "hexCode": "&#x290a;"
        },
        {
            "symbol": "⤋",
            "name": "Downwards Triple Arrow",
            "entityCode": "",
            "decimalCode": "&#10507;",
            "hexCode": "&#x290b;"
        },
        {
            "symbol": "⤌",
            "name": "Leftwards Double Dash Arrow",
            "entityCode": "",
            "decimalCode": "&#10508;",
            "hexCode": "&#x290c;"
        },
        {
            "symbol": "⤍",
            "name": "Rightwards Double Dash Arrow",
            "entityCode": "",
            "decimalCode": "&#10509;",
            "hexCode": "&#x290d;"
        },
        {
            "symbol": "⤎",
            "name": "Leftwards Triple Dash Arrow",
            "entityCode": "",
            "decimalCode": "&#10510;",
            "hexCode": "&#x290e;"
        },
        {
            "symbol": "⤏",
            "name": "Rightwards Triple Dash Arrow",
            "entityCode": "",
            "decimalCode": "&#10511;",
            "hexCode": "&#x290f;"
        },
        {
            "symbol": "⤐",
            "name": "Rightwards Two-Headed Triple Dash Arrow",
            "entityCode": "",
            "decimalCode": "&#10512;",
            "hexCode": "&#x2910;"
        },
        {
            "symbol": "⤑",
            "name": "Rightwards Arrow With Dotted Stem",
            "entityCode": "",
            "decimalCode": "&#10513;",
            "hexCode": "&#x2911;"
        },
        {
            "symbol": "⤒",
            "name": "Upwards Arrow To Bar",
            "entityCode": "",
            "decimalCode": "&#10514;",
            "hexCode": "&#x2912;"
        },
        {
            "symbol": "⤓",
            "name": "Downwards Arrow To Bar",
            "entityCode": "",
            "decimalCode": "&#10515;",
            "hexCode": "&#x2913;"
        },
        {
            "symbol": "⤔",
            "name": "Rightwards Arrow With Tail With Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#10516;",
            "hexCode": "&#x2914;"
        },
        {
            "symbol": "⤕",
            "name": "Rightwards Arrow With Tail With Double Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#10517;",
            "hexCode": "&#x2915;"
        },
        {
            "symbol": "⤖",
            "name": "Rightwards Two-Headed Arrow With Tail",
            "entityCode": "",
            "decimalCode": "&#10518;",
            "hexCode": "&#x2916;"
        },
        {
            "symbol": "⤗",
            "name": "Rightwards Two-Headed Arrow With Tail With Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#10519;",
            "hexCode": "&#x2917;"
        },
        {
            "symbol": "⤘",
            "name": "Rightwards Two-Headed Arrow With Tail With Double Vertical Stroke",
            "entityCode": "",
            "decimalCode": "&#10520;",
            "hexCode": "&#x2918;"
        },
        {
            "symbol": "⤙",
            "name": "Leftwards Arrow-Tail",
            "entityCode": "",
            "decimalCode": "&#10521;",
            "hexCode": "&#x2919;"
        },
        {
            "symbol": "⤚",
            "name": "Rightwards Arrow-Tail",
            "entityCode": "",
            "decimalCode": "&#10522;",
            "hexCode": "&#x291a;"
        },
        {
            "symbol": "⤛",
            "name": "Leftwards Double Arrow-Tail",
            "entityCode": "",
            "decimalCode": "&#10523;",
            "hexCode": "&#x291b;"
        },
        {
            "symbol": "⤜",
            "name": "Rightwards Double Arrow-Tail",
            "entityCode": "",
            "decimalCode": "&#10524;",
            "hexCode": "&#x291c;"
        },
        {
            "symbol": "⤝",
            "name": "Leftwards Arrow To Black Diamond",
            "entityCode": "",
            "decimalCode": "&#10525;",
            "hexCode": "&#x291d;"
        },
        {
            "symbol": "⤞",
            "name": "Rightwards Arrow To Black Diamond",
            "entityCode": "",
            "decimalCode": "&#10526;",
            "hexCode": "&#x291e;"
        },
        {
            "symbol": "⤟",
            "name": "Leftwards Arrow From Bar To Black Diamond",
            "entityCode": "",
            "decimalCode": "&#10527;",
            "hexCode": "&#x291f;"
        },
        {
            "symbol": "⤠",
            "name": "Rightwards Arrow From Bar To Black Diamond",
            "entityCode": "",
            "decimalCode": "&#10528;",
            "hexCode": "&#x2920;"
        },
        {
            "symbol": "⤡",
            "name": "North West And South East Arrow",
            "entityCode": "",
            "decimalCode": "&#10529;",
            "hexCode": "&#x2921;"
        },
        {
            "symbol": "⤢",
            "name": "North East And South West Arrow",
            "entityCode": "",
            "decimalCode": "&#10530;",
            "hexCode": "&#x2922;"
        },
        {
            "symbol": "⤣",
            "name": "North West Arrow With Hook",
            "entityCode": "",
            "decimalCode": "&#10531;",
            "hexCode": "&#x2923;"
        },
        {
            "symbol": "⤤",
            "name": "North East Arrow With Hook",
            "entityCode": "",
            "decimalCode": "&#10532;",
            "hexCode": "&#x2924;"
        },
        {
            "symbol": "⤥",
            "name": "South East Arrow With Hook",
            "entityCode": "",
            "decimalCode": "&#10533;",
            "hexCode": "&#x2925;"
        },
        {
            "symbol": "⤦",
            "name": "South West Arrow With Hook",
            "entityCode": "",
            "decimalCode": "&#10534;",
            "hexCode": "&#x2926;"
        },
        {
            "symbol": "⤧",
            "name": "North West Arrow And North East Arrow",
            "entityCode": "",
            "decimalCode": "&#10535;",
            "hexCode": "&#x2927;"
        },
        {
            "symbol": "⤨",
            "name": "North East Arrow And South East Arrow",
            "entityCode": "",
            "decimalCode": "&#10536;",
            "hexCode": "&#x2928;"
        },
        {
            "symbol": "⤩",
            "name": "South East Arrow And South West Arrow",
            "entityCode": "",
            "decimalCode": "&#10537;",
            "hexCode": "&#x2929;"
        },
        {
            "symbol": "⤪",
            "name": "South West Arrow And North West Arrow",
            "entityCode": "",
            "decimalCode": "&#10538;",
            "hexCode": "&#x292a;"
        },
        {
            "symbol": "⤫",
            "name": "Rising Diagonal Crossing Falling Diagonal",
            "entityCode": "",
            "decimalCode": "&#10539;",
            "hexCode": "&#x292b;"
        },
        {
            "symbol": "⤬",
            "name": "Falling Diagonal Crossing Rising Diagonal",
            "entityCode": "",
            "decimalCode": "&#10540;",
            "hexCode": "&#x292c;"
        },
        {
            "symbol": "⤭",
            "name": "South East Arrow Crossing North East Arrow",
            "entityCode": "",
            "decimalCode": "&#10541;",
            "hexCode": "&#x292d;"
        },
        {
            "symbol": "⤮",
            "name": "North East Arrow Crossing South East Arrow",
            "entityCode": "",
            "decimalCode": "&#10542;",
            "hexCode": "&#x292e;"
        },
        {
            "symbol": "⤯",
            "name": "Falling Diagonal Crossing North East Arrow",
            "entityCode": "",
            "decimalCode": "&#10543;",
            "hexCode": "&#x292f;"
        },
        {
            "symbol": "⤰",
            "name": "Rising Diagonal Crossing South East Arrow",
            "entityCode": "",
            "decimalCode": "&#10544;",
            "hexCode": "&#x2930;"
        },
        {
            "symbol": "⤱",
            "name": "North East Arrow Crossing North West Arrow",
            "entityCode": "",
            "decimalCode": "&#10545;",
            "hexCode": "&#x2931;"
        },
        {
            "symbol": "⤲",
            "name": "North West Arrow Crossing North East Arrow",
            "entityCode": "",
            "decimalCode": "&#10546;",
            "hexCode": "&#x2932;"
        },
        {
            "symbol": "⤳",
            "name": "Wave Arrow Pointing Directly Right",
            "entityCode": "",
            "decimalCode": "&#10547;",
            "hexCode": "&#x2933;"
        },
        {
            "symbol": "⤴",
            "name": "Arrow Pointing Rightwards Then Curving Upwards",
            "entityCode": "",
            "decimalCode": "&#10548;",
            "hexCode": "&#x2934;"
        },
        {
            "symbol": "⤵",
            "name": "Arrow Pointing Rightwards Then Curving Downwards",
            "entityCode": "",
            "decimalCode": "&#10549;",
            "hexCode": "&#x2935;"
        },
        {
            "symbol": "⤶",
            "name": "Arrow Pointing Downwards Then Curving Leftwards",
            "entityCode": "",
            "decimalCode": "&#10550;",
            "hexCode": "&#x2936;"
        },
        {
            "symbol": "⤷",
            "name": "Arrow Pointing Downwards Then Curving Rightwards",
            "entityCode": "",
            "decimalCode": "&#10551;",
            "hexCode": "&#x2937;"
        },
        {
            "symbol": "⤸",
            "name": "Right-Side Arc Clockwise Arrow",
            "entityCode": "",
            "decimalCode": "&#10552;",
            "hexCode": "&#x2938;"
        },
        {
            "symbol": "⤹",
            "name": "Left-Side Arc Anticlockwise Arrow",
            "entityCode": "",
            "decimalCode": "&#10553;",
            "hexCode": "&#x2939;"
        },
        {
            "symbol": "⤺",
            "name": "Top Arc Anticlockwise Arrow",
            "entityCode": "",
            "decimalCode": "&#10554;",
            "hexCode": "&#x293a;"
        },
        {
            "symbol": "⤻",
            "name": "Bottom Arc Anticlockwise Arrow",
            "entityCode": "",
            "decimalCode": "&#10555;",
            "hexCode": "&#x293b;"
        },
        {
            "symbol": "⤼",
            "name": "Top Arc Clockwise Arrow With Minus",
            "entityCode": "",
            "decimalCode": "&#10556;",
            "hexCode": "&#x293c;"
        },
        {
            "symbol": "⤽",
            "name": "Top Arc Anticlockwise Arrow With Plus",
            "entityCode": "",
            "decimalCode": "&#10557;",
            "hexCode": "&#x293d;"
        },
        {
            "symbol": "⤾",
            "name": "Lower Right Semicircular Clockwise Arrow",
            "entityCode": "",
            "decimalCode": "&#10558;",
            "hexCode": "&#x293e;"
        },
        {
            "symbol": "⤿",
            "name": "Lower Left Semicircular Anticlockwise Arrow",
            "entityCode": "",
            "decimalCode": "&#10559;",
            "hexCode": "&#x293f;"
        },
        {
            "symbol": "⥀",
            "name": "Anticlockwise Closed Circle Arrow",
            "entityCode": "",
            "decimalCode": "&#10560;",
            "hexCode": "&#x2940;"
        },
        {
            "symbol": "⥁",
            "name": "Clockwise Closed Circle Arrow",
            "entityCode": "",
            "decimalCode": "&#10561;",
            "hexCode": "&#x2941;"
        },
        {
            "symbol": "⥂",
            "name": "Rightwards Arrow Above Short Leftwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10562;",
            "hexCode": "&#x2942;"
        },
        {
            "symbol": "⥃",
            "name": "Leftwards Arrow Above Short Rightwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10563;",
            "hexCode": "&#x2943;"
        },
        {
            "symbol": "⥄",
            "name": "Short Rightwards Arrow Above Leftwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10564;",
            "hexCode": "&#x2944;"
        },
        {
            "symbol": "⥅",
            "name": "Rightwards Arrow With Plus Below",
            "entityCode": "",
            "decimalCode": "&#10565;",
            "hexCode": "&#x2945;"
        },
        {
            "symbol": "⥆",
            "name": "Leftwards Arrow With Plus Below",
            "entityCode": "",
            "decimalCode": "&#10566;",
            "hexCode": "&#x2946;"
        },
        {
            "symbol": "⥇",
            "name": "Rightwards Arrow Through X",
            "entityCode": "",
            "decimalCode": "&#10567;",
            "hexCode": "&#x2947;"
        },
        {
            "symbol": "⥈",
            "name": "Left Right Arrow Through Small Circle",
            "entityCode": "",
            "decimalCode": "&#10568;",
            "hexCode": "&#x2948;"
        },
        {
            "symbol": "⥉",
            "name": "Upwards Two-Headed Arrow From Small Circle",
            "entityCode": "",
            "decimalCode": "&#10569;",
            "hexCode": "&#x2949;"
        },
        {
            "symbol": "⥊",
            "name": "Left Barb Up Right Barb Down Harpoon",
            "entityCode": "",
            "decimalCode": "&#10570;",
            "hexCode": "&#x294a;"
        },
        {
            "symbol": "⥋",
            "name": "Left Barb Down Right Barb Up Harpoon",
            "entityCode": "",
            "decimalCode": "&#10571;",
            "hexCode": "&#x294b;"
        },
        {
            "symbol": "⥌",
            "name": "Up Barb Right Down Barb Left Harpoon",
            "entityCode": "",
            "decimalCode": "&#10572;",
            "hexCode": "&#x294c;"
        },
        {
            "symbol": "⥍",
            "name": "Up Barb Left Down Barb Right Harpoon",
            "entityCode": "",
            "decimalCode": "&#10573;",
            "hexCode": "&#x294d;"
        },
        {
            "symbol": "⥎",
            "name": "Left Barb Up Right Barb Up Harpoon",
            "entityCode": "",
            "decimalCode": "&#10574;",
            "hexCode": "&#x294e;"
        },
        {
            "symbol": "⥏",
            "name": "Up Barb Right Down Barb Right Harpoon",
            "entityCode": "",
            "decimalCode": "&#10575;",
            "hexCode": "&#x294f;"
        },
        {
            "symbol": "⥐",
            "name": "Left Barb Down Right Barb Down Harpoon",
            "entityCode": "",
            "decimalCode": "&#10576;",
            "hexCode": "&#x2950;"
        },
        {
            "symbol": "⥑",
            "name": "Up Barb Left Down Barb Left Harpoon",
            "entityCode": "",
            "decimalCode": "&#10577;",
            "hexCode": "&#x2951;"
        },
        {
            "symbol": "⥒",
            "name": "Leftwards Harpoon With Barb Up To Bar",
            "entityCode": "",
            "decimalCode": "&#10578;",
            "hexCode": "&#x2952;"
        },
        {
            "symbol": "⥓",
            "name": "Rightwards Harpoon With Barb Up To Bar",
            "entityCode": "",
            "decimalCode": "&#10579;",
            "hexCode": "&#x2953;"
        },
        {
            "symbol": "⥔",
            "name": "Upwards Harpoon With Barb Right To Bar",
            "entityCode": "",
            "decimalCode": "&#10580;",
            "hexCode": "&#x2954;"
        },
        {
            "symbol": "⥕",
            "name": "Downwards Harpoon With Barb Right To Bar",
            "entityCode": "",
            "decimalCode": "&#10581;",
            "hexCode": "&#x2955;"
        },
        {
            "symbol": "⥖",
            "name": "Leftwards Harpoon With Barb Down To Bar",
            "entityCode": "",
            "decimalCode": "&#10582;",
            "hexCode": "&#x2956;"
        },
        {
            "symbol": "⥗",
            "name": "Rightwards Harpoon With Barb Down To Bar",
            "entityCode": "",
            "decimalCode": "&#10583;",
            "hexCode": "&#x2957;"
        },
        {
            "symbol": "⥘",
            "name": "Upwards Harpoon With Barb Left To Bar",
            "entityCode": "",
            "decimalCode": "&#10584;",
            "hexCode": "&#x2958;"
        },
        {
            "symbol": "⥙",
            "name": "Downwards Harpoon With Barb Left To Bar",
            "entityCode": "",
            "decimalCode": "&#10585;",
            "hexCode": "&#x2959;"
        },
        {
            "symbol": "⥚",
            "name": "Leftwards Harpoon With Barb Up From Bar",
            "entityCode": "",
            "decimalCode": "&#10586;",
            "hexCode": "&#x295a;"
        },
        {
            "symbol": "⥛",
            "name": "Rightwards Harpoon With Barb Up From Bar",
            "entityCode": "",
            "decimalCode": "&#10587;",
            "hexCode": "&#x295b;"
        },
        {
            "symbol": "⥜",
            "name": "Upwards Harpoon With Barb Right From Bar",
            "entityCode": "",
            "decimalCode": "&#10588;",
            "hexCode": "&#x295c;"
        },
        {
            "symbol": "⥝",
            "name": "Downwards Harpoon With Barb Right From Bar",
            "entityCode": "",
            "decimalCode": "&#10589;",
            "hexCode": "&#x295d;"
        },
        {
            "symbol": "⥞",
            "name": "Leftwards Harpoon With Barb Down From Bar",
            "entityCode": "",
            "decimalCode": "&#10590;",
            "hexCode": "&#x295e;"
        },
        {
            "symbol": "⥟",
            "name": "Rightwards Harpoon With Barb Down From Bar",
            "entityCode": "",
            "decimalCode": "&#10591;",
            "hexCode": "&#x295f;"
        },
        {
            "symbol": "⥠",
            "name": "Upwards Harpoon With Barb Left From Bar",
            "entityCode": "",
            "decimalCode": "&#10592;",
            "hexCode": "&#x2960;"
        },
        {
            "symbol": "⥡",
            "name": "Downwards Harpoon With Barb Left From Bar",
            "entityCode": "",
            "decimalCode": "&#10593;",
            "hexCode": "&#x2961;"
        },
        {
            "symbol": "⥢",
            "name": "Leftwards Harpoon With Barb Up Above Leftwards Harpoon With Barb Down",
            "entityCode": "",
            "decimalCode": "&#10594;",
            "hexCode": "&#x2962;"
        },
        {
            "symbol": "⥣",
            "name": "Upwards Harpoon With Barb Left Beside Upwards Harpoon With Barb Right",
            "entityCode": "",
            "decimalCode": "&#10595;",
            "hexCode": "&#x2963;"
        },
        {
            "symbol": "⥤",
            "name": "Rightwards Harpoon With Barb Up Above Rightwards Harpoon With Barb Down",
            "entityCode": "",
            "decimalCode": "&#10596;",
            "hexCode": "&#x2964;"
        },
        {
            "symbol": "⥥",
            "name": "Downwards Harpoon With Barb Left Beside Downwards Harpoon With Barb Right",
            "entityCode": "",
            "decimalCode": "&#10597;",
            "hexCode": "&#x2965;"
        },
        {
            "symbol": "⥦",
            "name": "Leftwards Harpoon With Barb Up Above Rightwards Harpoon With Barb Up",
            "entityCode": "",
            "decimalCode": "&#10598;",
            "hexCode": "&#x2966;"
        },
        {
            "symbol": "⥧",
            "name": "Leftwards Harpoon With Barb Down Above Rightwards Harpoon With Barb Down",
            "entityCode": "",
            "decimalCode": "&#10599;",
            "hexCode": "&#x2967;"
        },
        {
            "symbol": "⥨",
            "name": "Rightwards Harpoon With Barb Up Above Leftwards Harpoon With Barb Up",
            "entityCode": "",
            "decimalCode": "&#10600;",
            "hexCode": "&#x2968;"
        },
        {
            "symbol": "⥩",
            "name": "Rightwards Harpoon With Barb Down Above Leftwards Harpoon With Barb Down",
            "entityCode": "",
            "decimalCode": "&#10601;",
            "hexCode": "&#x2969;"
        },
        {
            "symbol": "⥪",
            "name": "Leftwards Harpoon With Barb Up Above Long Dash",
            "entityCode": "",
            "decimalCode": "&#10602;",
            "hexCode": "&#x296a;"
        },
        {
            "symbol": "⥫",
            "name": "Leftwards Harpoon With Barb Down Below Long Dash",
            "entityCode": "",
            "decimalCode": "&#10603;",
            "hexCode": "&#x296b;"
        },
        {
            "symbol": "⥬",
            "name": "Rightwards Harpoon With Barb Up Above Long Dash",
            "entityCode": "",
            "decimalCode": "&#10604;",
            "hexCode": "&#x296c;"
        },
        {
            "symbol": "⥭",
            "name": "Rightwards Harpoon With Barb Down Below Long Dash",
            "entityCode": "",
            "decimalCode": "&#10605;",
            "hexCode": "&#x296d;"
        },
        {
            "symbol": "⥮",
            "name": "Upwards Harpoon With Barb Left Beside Downwards Harpoon With Barb Right",
            "entityCode": "",
            "decimalCode": "&#10606;",
            "hexCode": "&#x296e;"
        },
        {
            "symbol": "⥯",
            "name": "Downwards Harpoon With Barb Left Beside Upwards Harpoon With Barb Right",
            "entityCode": "",
            "decimalCode": "&#10607;",
            "hexCode": "&#x296f;"
        },
        {
            "symbol": "⥰",
            "name": "Right Double Arrow With Rounded Head",
            "entityCode": "",
            "decimalCode": "&#10608;",
            "hexCode": "&#x2970;"
        },
        {
            "symbol": "⥱",
            "name": "Equals Sign Above Rightwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10609;",
            "hexCode": "&#x2971;"
        },
        {
            "symbol": "⥲",
            "name": "Tilde Operator Above Rightwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10610;",
            "hexCode": "&#x2972;"
        },
        {
            "symbol": "⥳",
            "name": "Leftwards Arrow Above Tilde Operator",
            "entityCode": "",
            "decimalCode": "&#10611;",
            "hexCode": "&#x2973;"
        },
        {
            "symbol": "⥴",
            "name": "Rightwards Arrow Above Tilde Operator",
            "entityCode": "",
            "decimalCode": "&#10612;",
            "hexCode": "&#x2974;"
        },
        {
            "symbol": "⥵",
            "name": "Rightwards Arrow Above Almost Equal To",
            "entityCode": "",
            "decimalCode": "&#10613;",
            "hexCode": "&#x2975;"
        },
        {
            "symbol": "⥶",
            "name": "Less-Than Above Leftwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10614;",
            "hexCode": "&#x2976;"
        },
        {
            "symbol": "⥷",
            "name": "Leftwards Arrow Through Less-Than",
            "entityCode": "",
            "decimalCode": "&#10615;",
            "hexCode": "&#x2977;"
        },
        {
            "symbol": "⥸",
            "name": "Greater-Than Above Rightwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10616;",
            "hexCode": "&#x2978;"
        },
        {
            "symbol": "⥹",
            "name": "Subset Above Rightwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10617;",
            "hexCode": "&#x2979;"
        },
        {
            "symbol": "⥺",
            "name": "Leftwards Arrow Through Subset",
            "entityCode": "",
            "decimalCode": "&#10618;",
            "hexCode": "&#x297a;"
        },
        {
            "symbol": "⥻",
            "name": "Superset Above Leftwards Arrow",
            "entityCode": "",
            "decimalCode": "&#10619;",
            "hexCode": "&#x297b;"
        },
        {
            "symbol": "⥼",
            "name": "Left Fish Tail",
            "entityCode": "",
            "decimalCode": "&#10620;",
            "hexCode": "&#x297c;"
        },
        {
            "symbol": "⥽",
            "name": "Right Fish Tail",
            "entityCode": "",
            "decimalCode": "&#10621;",
            "hexCode": "&#x297d;"
        },
        {
            "symbol": "⥾",
            "name": "Up Fish Tail",
            "entityCode": "",
            "decimalCode": "&#10622;",
            "hexCode": "&#x297e;"
        },
        {
            "symbol": "⥿",
            "name": "Down Fish Tail",
            "entityCode": "",
            "decimalCode": "&#10623;",
            "hexCode": "&#x297f;"
        }
    ]
});